import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SellersSidebar from "../dashboardComponents/SideBar";
import { axiosInstance } from "../../../components/baseUrl";
import { ProtectedRoutes } from "../../../components/ProtectedRoutes";
// import Search from "../dashboardComponents/Search";
// import PaginationComponent from "../../../components/PaginationComponent";
import {
  Capitalize,
  convertDateFormat,
  numberWithCommas,
} from "../../../helpers/hellpersFunctions";
import Pagination from "react-responsive-pagination";
import { AppContext } from "../../../components/AppState";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CardsLoader from "../../../components/skeletonLoaders/CardsLoader";
import TableLoader from "../../../components/skeletonLoaders/TableLoader";

const Transaction = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [summaryLoader, setSummaryLoader] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  const [activitySummary, setActivitySummary] = useState("");
  // const [search, setSearch] = useState("");
  const [noMatch, setNoMatch] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;

  const { user } = useContext(AppContext);

  const navigate = useNavigate();

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const fetchAllOrders = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(`/order?page=${currentPage}`);
      setTransactions(data.data.orders);
      const totalOrders = data.data.pagination.totalOrders;
      setTotalPages(Math.ceil(totalOrders / itemsPerPage));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      } else
        toast.error(`${error.response.data.errors}`, {
          position: "top-right",
          autoClose: 4000,
          pauseHover: true,
          draggable: true,
        });
    }
  };

  const fetchOrderByPartner = async (partner) => {
    setLoading(true);
    try {
      await axiosInstance
        .post(`/dashboard/admin/seller-activity-summary?page=${currentPage}`, {
          hearAboutUs: partner,
        })
        .then((response) => {
          setTransactions(response.data.data.all_orders.orders);
          const totalOrders =
            response.data.data.all_orders.pagination.totalOrders;
          setTotalPages(Math.ceil(totalOrders / itemsPerPage));
          setActivitySummary(response.data.data);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      } else
        toast.error(`${error.response.data.errors}`, {
          position: "top-right",
          autoClose: 4000,
          pauseHover: true,
          draggable: true,
        });
    }
  };

  const allTransactionSummary = async () => {
    setSummaryLoader(true);
    try {
      await axiosInstance
        .get("/dashboard/admin/activity-summary")
        .then((response) => {
          setActivitySummary(response.data.data);
          setSummaryLoader(false);
        });
    } catch (error) {
      console.log(error);
      setSummaryLoader(false);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      } else
        toast.error(`${error.response.data.errors}`, {
          position: "top-right",
          autoClose: 4000,
          pauseHover: true,
          draggable: true,
        });
    }
  };

  const filterOrderBasedOnPartner = (e) => {
    const selectedPartner = e.target.value;
    if (selectedPartner === "all-orders") {
      fetchAllOrders();
      allTransactionSummary();
    } else {
      fetchOrderByPartner(selectedPartner);
    }
  };

  useEffect(() => {
    if (
      user?.role === "SUPER_ADMIN" ||
      user?.role === "MARKETPLACE_ADMIN" ||
      user?.role === "FINANCE" ||
      user?.role === "SOURCE_PRO_ADMIN"
    ) {
      fetchAllOrders();
      allTransactionSummary();
    } else if (user?.role === "BUYER" || user?.role === "SELLER") {
      navigate("/unauthorized");
    } else {
      fetchOrderByPartner(user?.role);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <>
      <div className="grid-container">
        <ToastContainer />
        <header className="header">
          <div className="header__message me-5">
            <h2>All Transactions</h2>
          </div>

          <div className="user-area ms-auto">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 user-area-art">
                {user.firstName && user.firstName.charAt(0).toUpperCase()}
              </div>
              <div className="flex-grow-1 ms-2">
                {user.firstName &&
                user.firstName.length + " " + user.LastName.length > 15 ? (
                  <p className="">
                    {Capitalize(user.firstName).slice(0, 15)}...
                  </p>
                ) : (
                  <p className="">
                    {user.firstName && Capitalize(user.firstName)}{" "}
                    {user.LastName}{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
        </header>

        <SellersSidebar />

        <main className="main">
          <h1 className="section-title">Activity Summary</h1>
          {loading || summaryLoader ? (
            <CardsLoader />
          ) : (
            <div className="main-overview">
              <div className="overview-card">
                <div>
                  <h2>Completed Transacion Revenue</h2>

                  <div className="d-flex justify-content-between mt-4">
                    <h3>USD {activitySummary.total_transactions_revenue}</h3>
                  </div>
                </div>
              </div>
              <div className="overview-card">
                <div>
                  <h2>Completed Orders</h2>

                  <div className="d-flex justify-content-between mt-4">
                    <h3>{activitySummary.total_delivered_orders}</h3>
                  </div>
                </div>
              </div>
              <div className="overview-card">
                <div>
                  <h2>Ongoing Orders</h2>

                  <div className="d-flex justify-content-between mt-4">
                    <h3>
                      {activitySummary &&
                        activitySummary.total_pending_orders +
                          activitySummary.total_shipped_orders +
                          activitySummary.total_confirmed_orders}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="overview-card">
                <div>
                  <h2>Earned Commission</h2>
                  {/* <p>Detailed transaction history is on the order page</p> */}
                  <div className="d-flex justify-content-between mt-4">
                    <h3>
                      USD{" "}
                      {activitySummary?.total_transactions_revenue &&
                        numberWithCommas(
                          activitySummary.total_transactions_revenue * 0.05
                        )}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="section-title-ctn">
            {user?.role === "SUPER_ADMIN" ||
            user?.role === "MARKETPLACE_ADMIN" ||
            user?.role === "FINANCE" ||
            user?.role === "SOURCE_PRO_ADMIN" ? (
              <div className="header__search w-full">
                {" "}
                <label className="">Filter transactions by partner</label>
                <div className="my-2">
                  <select
                    className="form-select"
                    onChange={filterOrderBasedOnPartner}
                    style={{ width: "10rem", borderRadius: "4px" }}
                  >
                    <option>Select Seller</option>
                    <option value="all-orders">All Transactions</option>
                    <option value="OKOBI">OKOBI Transactions</option>
                    <option value="AFCTCA">AFCFTA Transactions</option>
                    <option value="FARMBENE">FARMBENE Transactions</option>
                  </select>
                </div>
              </div>
            ) : null}
            {/* <div className="header__search">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div> */}
          </div>

          {loading ? (
            <TableLoader />
          ) : (
            <>
              {/* <h1 className="section-title">All Orders</h1> */}
              <div className="main-overview">
                <div className="overview-card no-padding">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Date created</th>
                          <th>Porduct Name</th>
                          <th scope="col">Order No</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Payment term</th>
                          <th scope="col">Incoterm</th>
                          <th scope="col">Status</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions &&
                          transactions.map((transaction, index) => {
                            return (
                              <tr key={transaction.id}>
                                <td>
                                  {transaction.createdAt &&
                                    convertDateFormat(transaction.createdAt)}
                                </td>
                                <td>
                                  <div className="d-flex">
                                    <div className="flex-grow-1 ms-3">
                                      <p className="text-center">
                                        {transaction.product
                                          ? transaction.product.productName
                                          : transaction.productName}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>{transaction.orderNumber}</td>
                                <td>USD {transaction.cost}</td>
                                <td>{transaction.paymentTerm}</td>
                                <td>{transaction.incoterm}</td>
                                <td>
                                  {transaction.status === "PENDING" && (
                                    <span className="badge rounded-pill bg-warning text-dark">
                                      Pending
                                    </span>
                                  )}
                                  {transaction.status === "PROCESSING" && (
                                    <span className="badge rounded-pill bg-primary">
                                      Processing
                                    </span>
                                  )}
                                  {transaction.status === "SHIPPED" && (
                                    <span className="badge rounded-pill bg-info text-dark">
                                      Shipped
                                    </span>
                                  )}
                                  {transaction.status === "DELIVERED" && (
                                    <span className="badge rounded-pill bg-success">
                                      Delivered
                                    </span>
                                  )}
                                  {transaction.status === "CANCELLED" && (
                                    <span className="badge rounded-pill bg-danger">
                                      Cancelled
                                    </span>
                                  )}
                                  {/* <div className="text-warning">
                                {transaction.status}
                              </div> */}
                                </td>
                                <td>
                                  <Link to={`/view-order/${transaction.id}`}>
                                    View
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {noMatch === true ? (
                <div className="empty-state">
                  <h4>No results found</h4>
                  <p>
                    No search matched your criteria. Try searching for something
                    else.
                  </p>
                </div>
              ) : (
                <div className="pag-ctn">
                  <Pagination
                    current={currentPage}
                    total={totalPages}
                    onPageChange={handlePageChange}
                    extraClassName="justify-content-start"
                  />
                </div>
              )}
            </>
          )}
        </main>
      </div>
    </>
  );
};

export default ProtectedRoutes(Transaction, [
  "SOURCE_PRO_ADMIN",
  "SUPER_ADMIN",
  "FINANCE",
  "AFCTCA",
  "OKOBI",
  "FARMBENE",
]);
